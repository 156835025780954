import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiTrash2, FiEye } from "react-icons/fi";
import { doGetAllUsers } from "../../apis/userAxios";

function ViewMerchants() {
  const navigate = useNavigate();
  const [filterName, setFilterName] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterKyc, setFilterkyc] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);

  const itemsPerPage = 50;
  const admin = localStorage.getItem("admin");
  const adminDetails = JSON.parse(admin);

  const today = new Date().toISOString().split("T")[0]; // Today's date in YYYY-MM-DD format
  useEffect(() => {
    if (!adminDetails) {
      navigate("/login");
    }
    const fetchAdmins = async () => {
      try {
        const payload = {
          strLoginUserId: adminDetails.fkAdminId,
          userType: "merchant",
          strSearchName: filterName || "",
          kycStatus: filterKyc || "",
          strFromDate: filterDate || "",
          strEndDate: filterEndDate || "",
          intSkipCount: (currentPage - 1) * itemsPerPage,
          intPageLimit: itemsPerPage,
        };
        doGetAllUsers(payload, adminDetails.token)
          .then((res) => {
            if (res.success === false) {
              setUsers([]);
            } else {
              setUsers(res.data);
              setCount(res.count);
            }
          })
          .catch((error) => {
            if (error.status === 401) {
              localStorage.removeItem("admin");
              navigate("/login");
            }
          });
      } catch (error) {
        if (error.status === 401) {
          localStorage.removeItem("admin");
          navigate("/login");
        }
      }
    };

    fetchAdmins();
  }, [currentPage, filterName, filterDate, filterEndDate, filterKyc]);

  const handleClear = () => {
    setFilterName("");
    setFilterDate("");
    setFilterEndDate("");
    setFilterkyc("");
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNavigate = (id) => {
    navigate(`/user/view/${id}`);
  };

  return (
    <div className="ml-[260px]">
      <div className="bg-white p-6 mt-5 mx-6 border mb-6 rounded-lg">
        <h3 className="text-lg font-medium mb-4">Filter Merchants</h3>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-5">
          {/* Name Search */}
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              placeholder="Enter Your Name"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              className="mt-1 px-3 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div>

          {/* From Date Search */}
          <div>
            <label
              htmlFor="date"
              className="block text-sm font-medium text-gray-700"
            >
              From Date
            </label>
            <input
              type="date"
              id="date"
              value={filterDate}
              max={today}
              onChange={(e) => setFilterDate(e.target.value)}
              className="mt-1 h-10 px-3 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          {/* To Date Search */}
          <div>
            <label
              htmlFor="date"
              className="block text-sm font-medium text-gray-700"
            >
              To Date
            </label>
            <input
              type="date"
              id="date"
              value={filterEndDate}
              min={filterDate} // Set min as the selected from date
              max={today} // Restrict to today's date
              onChange={(e) => setFilterEndDate(e.target.value)}
              className="mt-1 h-10 px-3 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          {/* KYC Status Dropdown */}
          <div>
            <label
              htmlFor="type"
              className="block text-sm font-medium text-gray-700"
            >
              KYC
            </label>
            <select
              id="type"
              value={filterKyc}
              onChange={(e) => setFilterkyc(e.target.value)}
              className="mt-1 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="">Select Type</option>
              <option value="PENDING">PENDING</option>
              <option value="COMPLETED">APPROVED</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={handleClear}
            className="px-4 w-1/6 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-800 transition-colors duration-200"
          >
            Clear
          </button>
          <button
            type="submit"
            className="px-4 py-2 w-1/6 bg-gray-800 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
          >
            Update
          </button>
        </div>
      </div>

      {/* View Users Table */}
      <div className="p-6 bg-white rounded-lg shadow-md mt-6 mx-6 mb-10 h-auto">
        <h2 className="text-lg font-medium mb-6">View Users</h2>
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-800 text-white text-sm leading-normal">
              <th className="py-3 px-6 text-left">ID</th>
              <th className="py-3 px-6 text-left">CompanyName</th>
              <th className="py-3 px-6 text-left">LicenseNo</th>
              <th className="py-3 px-6 text-left">PhNo</th>
              <th className="py-3 px-6 text-left">Account_Status</th>
              <th className="py-3 px-6 text-left">Wallet_Balance</th>
              <th className="py-3 px-6 text-left">Date_Of_Join</th>
              <th className="py-3 px-6 text-left">Kyc_Status</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 text-sm font-light">
            {users.map((user) => (
              <tr
                key={user.id}
                className="border-b border-gray-200 hover:bg-gray-100 font-normal"
              >
                <td className="py-3 px-6 text-left">{user.fkUserId}</td>
                <td className="py-3 px-6 text-left">{user.companyName}</td>
                <td className="py-3 px-6 text-left">{user.companyLicenseNo}</td>
                <td className="py-3 px-6 text-left">{user.strPhoneNo}</td>
                <td className="py-3 px-6 text-left">{user.accountStatus}</td>
                <td className="py-3 px-6 text-left">
                  {user.currency + " " + user.walletBalence}
                </td>
                <td className="py-3 px-6 text-left">
                  {new Date(user.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </td>
                <td className="py-3 px-6 text-left">
                  <span
                    className={`px-2 py-1 rounded-md text-white font-medium ${
                      user.statusKYC === "COMPLETED"
                        ? "bg-green-400"
                        : "bg-red-400"
                    }`}
                  >
                    {user.statusKYC}
                  </span>
                </td>
                <td className="py-3 px-6 text-center">
                  <div className="flex justify-center gap-2">
                    <div className="bg-red-100 text-red-400 flex justify-center items-center rounded-md w-8 h-8 hover:scale-105 cursor-pointer">
                      <FiEye
                        className="text-md"
                        onClick={() => handleNavigate(user.fkUserId)}
                      />
                    </div>
                    <div className="bg-green-100 text-green-400 flex justify-center items-center rounded-md w-8 h-8 hover:scale-105 cursor-pointer">
                      <FiEdit className="text-md" />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div className="mt-4 flex justify-center space-x-2">
          {/* First Page Button */}
          {currentPage > 1 && (
            <button
              onClick={() => handlePageChange(1)}
              className="px-3 py-1 rounded-lg bg-gray-200"
            >
              First
            </button>
          )}

          {/* Page Number Buttons */}
          {[...Array(Math.ceil(count / itemsPerPage))].map((_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`px-3 py-1 rounded-lg ${
                currentPage === index + 1
                  ? "bg-gray-800 text-white"
                  : "bg-gray-200"
              }`}
            >
              {index + 1}
            </button>
          ))}

          {/* Last Page Button */}
          {currentPage < Math.ceil(count / itemsPerPage) && (
            <button
              onClick={() => handlePageChange(Math.ceil(100 / itemsPerPage))}
              className="px-3 py-1 rounded-lg bg-gray-200"
            >
              Last
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewMerchants;
