import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit, FiTrash2, FiEye } from "react-icons/fi";
import { doGetTransactions } from "../../apis/transactions";

function Transactions() {
  const navigate = useNavigate();
  const [filterMode, setFilterMode] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);

  const itemsPerPage = 20;
  const admin = localStorage.getItem("admin");
  const adminDetails = JSON.parse(admin);

  const today = new Date().toISOString().split("T")[0]; // Today's date in YYYY-MM-DD format

  useEffect(() => {
    if (!adminDetails) {
      navigate("/login");
    }
    searchFilter();
  }, [
    filterMode,
    filterStatus,
    filterDate,
    filterEndDate,
    filterType,
    currentPage,
  ]);

  //   useEffect(() => {
  //     if (!adminDetails) {
  //       navigate("/login");
  //     }
  //     const fetchAdmins = async () => {
  //       try {
  //         const payload = {
  //           strLoginUserId: adminDetails.fkAdminId,
  //           userType: "user",
  //           paymentType: filterType || "",
  //           paymentMode: filterMode || "",
  //           paymentStatus: filterStatus || "",
  //           strFromDate: filterDate || "",
  //           strEndDate: filterEndDate || "",
  //           intSkipCount: (currentPage - 1) * itemsPerPage,
  //           intPageLimit: itemsPerPage,
  //         };
  //         doGetTransactions(payload, adminDetails.token)
  //           .then((res) => {
  //             if (res.success === false) {
  //               setUsers([]);
  //             } else {
  //               setUsers(res.data);
  //               setCount(res.count);
  //             }
  //           })
  //           .catch((error) => {
  //             if (error.status === 401) {
  //               localStorage.removeItem("admin");
  //               navigate("/login");
  //             }
  //           });
  //       } catch (error) {
  //         if (error.status === 401) {
  //           localStorage.removeItem("admin");
  //           navigate("/login");
  //         }
  //       }
  //     };

  //     fetchAdmins();
  //   }, [
  //     currentPage,
  //     filterMode,
  //     filterDate,
  //     filterEndDate,
  //     filterStatus,
  //     filterType,
  //   ]);
  const fetchData = async () => {
    try {
      setLoading(true);
      console.log("inside serch filter" + adminDetails);
      const payload = {
        strLoginUserId: adminDetails.fkAdminId,
        paymentType: filterType || "",
        paymentMode: filterMode || "",
        paymentStatus: filterStatus || "",
        strFromDate: filterDate || "",
        strEndDate: filterEndDate || "",
        intSkipCount: (currentPage - 1) * itemsPerPage,
        intPageLimit: itemsPerPage,
      };
      doGetTransactions(payload, adminDetails.token)
        .then((res) => {
          if (res.success === false) {
            setUsers([]);
          } else {
            setUsers(res.data);
            setCount(res.count);
          }
        })
        .catch((error) => {
          if (error.status === 401) {
            localStorage.removeItem("admin");
            navigate("/login");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {}
  };
  const handleClear = () => {
    setFilterMode("");
    setFilterStatus("");
    setFilterDate("");
    setFilterEndDate("");
    setFilterType("");
    searchFilter();
  };
  const searchFilter = () => {
    fetchData(); // Call the fetch function to get filtered results
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNavigate = (id) => {
    navigate(`/user/view/${id}`);
  };

  return (
    <div className="ml-[260px]">
      {loading ? (
        <div className="h-screen ml-[700px]  mt-[300px] items-center">
          <p className="text-gray-500 text-3xl font-medium">Loading...</p>
        </div>
      ) : (
        <div>
          <div className="bg-white p-6 mt-5 mx-6 border mb-6 rounded-lg">
            <h3 className="text-lg font-medium mb-4">Filter Transactions</h3>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-5">
              {/* <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              paymentType
            </label>
            <input
              type="text"
              id="name"
              placeholder="Enter Your Name"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              className="mt-1 px-3 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm sm:text-sm"
            />
          </div> */}

              {/* payment soure */}
              <div>
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Payment Mode
                </label>
                <select
                  id="type"
                  value={filterMode}
                  onChange={(e) => setFilterMode(e.target.value)}
                  className="mt-1 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Select Type</option>
                  <option value="paypal">Paypal</option>
                  <option value="Card">Monnect</option>
                  <option value="Mercurio">Crypto</option>
                  <option value="System">System</option>
                </select>
              </div>
              {/* payment status */}
              <div>
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Payment Status
                </label>
                <select
                  id="type"
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="mt-1 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Select Type</option>
                  <option value="">All</option>
                  <option value="approved">Approved</option>
                  <option value="created">Created</option>
                  <option value="pending">Pending</option>
                  <option value="scheduled">Scheduled</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="error">Failed</option>
                </select>
              </div>
              {/* From Date Search */}
              <div>
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  From Date
                </label>
                <input
                  type="date"
                  id="date"
                  value={filterDate}
                  max={today}
                  onChange={(e) => setFilterDate(e.target.value)}
                  className="mt-1 h-10 px-3 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              {/* To Date Search */}
              <div>
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  To Date
                </label>
                <input
                  type="date"
                  id="date"
                  value={filterEndDate}
                  min={filterDate} // Set min as the selected from date
                  max={today} // Restrict to today's date
                  onChange={(e) => setFilterEndDate(e.target.value)}
                  className="mt-1 h-10 px-3 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* payment ty[e] Status Dropdown */}
              <div>
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Payment Type
                </label>
                <select
                  id="type"
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  className="mt-1 h-10 block w-full bg-slate-100 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Select Type</option>
                  <option value="Credit">Credit</option>
                  <option value="Debit">Debit</option>
                  <option value="Neutral">Neutral</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={handleClear}
                className="px-4 w-1/6 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-800 transition-colors duration-200"
              >
                Clear
              </button>
              {/* <button
                type="submit"
                className="px-4 py-2 w-1/6 bg-gray-800 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
                onClick={searchFilter}
              >
                Update
              </button> */}
            </div>
          </div>

          {/* View Users Table */}
          <div className="p-6 bg-white rounded-lg shadow-md mt-6 mx-6 mb-10 h-auto">
            <h2 className="text-lg font-medium mb-6">View Transaction</h2>
            <table className="w-full table-auto">
              <thead>
                <tr className="bg-gray-800 text-white text-sm leading-normal">
                  <th className="py-3 px-6 text-left">ID</th>
                  <th className="py-3 px-6 text-left">SendName</th>
                  <th className="py-3 px-6 text-left">Transaction</th>
                  <th className="py-3 px-6 text-left">Amount</th>
                  <th className="py-3 px-6 text-left">Source</th>
                  <th className="py-3 px-6 text-left">Type</th>
                  <th className="py-3 px-6 text-left">Status</th>
                  <th className="py-3 px-6 text-left">Date</th>

                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {users.map((user) => (
                  <tr
                    key={user.id}
                    className="border-b border-gray-200 hover:bg-gray-100 font-normal"
                  >
                    <td className="py-3 px-6 text-left">
                      {user.payment_Id ? user.payment_Id : user.trans_Id}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {/* {user.SendrName} to{" "}
                      {user.RcvrName ? user.RcvrName : "Nil"} */}
                      {user.SendrName}
                      {user.transaction_Type === "Transfer" && user.RcvrName ? (
                        <> to {user.RcvrName}</>
                      ) : null}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user.transaction_Type}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user.currency + " " + user.amount}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user.paymentSource}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user.systemPaymentType}
                    </td>
                    <td className="py-3 px-6 text-left">
                      <span
                        className={`px-2 py-1 rounded-md text-white font-medium ${
                          user.payment_Status === "approved"
                            ? "bg-green-400"
                            : "bg-red-400"
                        }`}
                      >
                        {user.payment_Status}
                      </span>
                    </td>
                    <td className="py-3 px-6 text-left">
                      {new Date(user.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </td>

                    <td className="py-3 px-6 text-center">
                      <div className="flex justify-center gap-2">
                        <div className="bg-red-100 text-red-400 flex justify-center items-center rounded-md w-8 h-8 hover:scale-105 cursor-pointer">
                          <FiEye
                            className="text-md"
                            onClick={() => handleNavigate(user.payment_Id)}
                          />
                        </div>
                        {/* <div className="bg-green-100 text-green-400 flex justify-center items-center rounded-md w-8 h-8 hover:scale-105 cursor-pointer">
                      <FiEdit className="text-md" />
                    </div> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination */}
            <div className="mt-4 flex justify-center space-x-2">
              {/* First Page Button */}
              {currentPage > 1 && (
                <button
                  onClick={() => handlePageChange(1)}
                  className="px-3 py-1 rounded-lg bg-gray-200"
                >
                  First
                </button>
              )}

              {/* Page Number Buttons */}
              {[...Array(Math.ceil(count / itemsPerPage))].map((_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-3 py-1 rounded-lg ${
                    currentPage === index + 1
                      ? "bg-gray-800 text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {index + 1}
                </button>
              ))}

              {/* Last Page Button */}
              {currentPage < Math.ceil(count / itemsPerPage) && (
                <button
                  onClick={() =>
                    handlePageChange(Math.ceil(100 / itemsPerPage))
                  }
                  className="px-3 py-1 rounded-lg bg-gray-200"
                >
                  Last
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Transactions;
