import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  LineChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { doGetDashboard } from "../../apis/dashboardAxios";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [withdetails, setWithdetails] = useState([]);
  const [grpah, setGraph] = useState([]);
  const [count, setCount] = useState({});
  const [users, setUsers] = useState([]);
  // Sample data for the graphs
  const data = [
    { name: "Jan", pv: 2400 },
    { name: "Feb", pv: 1398 },
    { name: "Mar", pv: 9800 },
    { name: "Apr", pv: 3908 },
    { name: "May", pv: 4800 },
    { name: "Jun", pv: 3800 },
    { name: "Jul", pv: 4300 },
    { name: "Aug", pv: 5400 },
    { name: "Sep", pv: 5400 },
    { name: "Oct", pv: 5400 },
    { name: "nav", pv: 5400 },
    { name: "Des", pv: 5400 },
  ];
  // Sample data for the bar graph
  const barData = [
    { name: "Jan", uv: 4000, pv: 2400, amt: 2400 },
    { name: "Feb", uv: 3000, pv: 1398, amt: 2210 },
    { name: "Mar", uv: 2000, pv: 9800, amt: 2290 },
    { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
    { name: "May", uv: 1890, pv: 4800, amt: 2181 },
    { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
    { name: "Jul", uv: 3490, pv: 4300, amt: 2100 },
    { name: "Aug", uv: 4000, pv: 2400, amt: 2400 },
    { name: "Sep", uv: 3000, pv: 1398, amt: 2210 },
    { name: "Oct", uv: 2000, pv: 9800, amt: 2290 },
    { name: "Nov", uv: 2780, pv: 3908, amt: 2000 },
    { name: "Dec", uv: 1890, pv: 4800, amt: 2181 },
  ];

  //new
  const admin = localStorage.getItem("admin");
  const adminDetails = JSON.parse(admin);

  useEffect(() => {
    if (!adminDetails) {
      navigate("/login");
    }
    const fetchDashboard = async () => {
      try {
        const payload = {
          strLoginUserId: adminDetails.fkAdminId,
        };
        doGetDashboard(payload, adminDetails.token)
          .then((res) => {
            if (res.success === false) {
              // setUsers([]);
            } else {
              setCount(res.data.summary);
              setWithdetails(res.data.withdrawal);
              setUsers(res.data.latestUsers);
              // Prepare graph data from API response
              const formattedGraphData = res.data.dailyTransactions.map(
                (item) => {
                  const dayData = { name: item._id }; // _id is the date
                  item.transactions.forEach((transaction) => {
                    dayData[transaction.type] = transaction.totalAmount;
                  });
                  return dayData;
                }
              );

              setGraph(formattedGraphData);
            }
          })
          .catch((error) => {
            if (error.status === 401) {
              localStorage.removeItem("admin");
              navigate("/login");
            }
          });
      } catch (error) {
        if (error.status === 401) {
          localStorage.removeItem("admin");
          navigate("/login");
        }
      }
    };

    fetchDashboard();
  }, []);
  //end
  const truncateObjectId = (id) => {
    if (!id) return "";
    const length = 3; // Number of characters to show at the start and end
    return `${id.substring(0, length)}...${id.substring(id.length - length)}`;
  };

  return (
    <div className="ml-[256px]">
      <div className="min-h-screen bg-gray-100 text-white p-10 mx-1  ">
        {/* Header */}
        <div className="flex justify-between items-center mb-8 ">
          <h1 className="text-2xl font-bold text-black">Admin Dashboard</h1>
          <div className="flex items-center">
            <div className="mr-4">John Doe</div>
            <img
              src="https://via.placeholder.com/40"
              alt="Profile"
              className="w-10 h-10 rounded-full"
            />
          </div>
        </div>

        {/* Overview Cards */}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 mb-6">
          <div className="bg-white text-black shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow cursor-pointer">
            <div className="flex items-center">
              <div className="w-12 h-12 bg-gray-800 text-black rounded-full flex items-center justify-center">
                <i className="fas fa-users"></i>
              </div>
              <div className="ml-4">
                <h2 className="text-lg font-medium">Total Users</h2>
                <p className="text-3xl font-bold">{count.userCount}</p>
              </div>
            </div>
          </div>
          <div className="bg-gray-800 text-white hover:bg-gray-600  shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow cursor-pointer">
            <div className="flex items-center">
              <div className="w-12 h-12 bg-white text-black rounded-full flex items-center justify-center">
                <i className="fas fa-wallet"></i>
              </div>
              <div className="ml-4">
                <h2 className="text-lg font-medium">Total Transactions</h2>
                <p className="text-3xl font-bold">
                  {count.totalTransactionCount}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white  text-black shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow cursor-pointer">
            <div className="flex items-center">
              <div className="w-12 h-12 bg-gray-800 text-black rounded-full flex items-center justify-center">
                <i className="fas fa-dollar-sign"></i>
              </div>
              <div className="ml-4">
                <h2 className="text-lg font-medium">Total Deposits</h2>
                <p className="text-3xl font-bold">${count.depositAmount}</p>
              </div>
            </div>
          </div>
          <div className="bg-gray-800 hover:bg-gray-600  text-white shadow-lg rounded-lg p-5 hover:shadow-xl transition-shadow cursor-pointer">
            <div className="flex items-center">
              <div className="w-12 h-12 bg-white text-black rounded-full flex items-center justify-center">
                <i className="fas fa-user-check"></i>
              </div>
              <div className="ml-4">
                <h2 className="text-lg font-medium">Pending KYCs</h2>
                <p className="text-3xl font-bold">{count.kycPendingCOunt}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Transaction Volume Chart */}
        <div className="flex gap-2">
          <div className="bg-gray-800 text-white shadow-lg rounded-lg p-5 mb-6 w-3/6">
            <h3 className="text-lg font-medium mb-4">Transaction Volume</h3>
            {/* <ResponsiveContainer width="100%" height={300}>
              <LineChart data={data}>
                <CartesianGrid stroke="#333" />
                <XAxis dataKey="name" stroke="#ccc" />
                <YAxis stroke="#ccc" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="pv" stroke="#ffffff" />
              </LineChart>
            </ResponsiveContainer> */}

            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={grpah}>
                <CartesianGrid stroke="#333" />
                <XAxis dataKey="name" stroke="#ccc" />
                <YAxis
                  stroke="#ccc"
                  // tickFormatter={(value) => `${value}`} // Keep this or remove if unnecessary
                  domain={["auto", "auto"]} // Auto domain based on data values
                  ticks={[0, 30, 60, 90, 120, 150, 180]} // Specify ticks, adjust based on data range
                />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="Deposit" stroke="#82ca9d" />
                <Line type="monotone" dataKey="Withdrawal" stroke="#ff7300" />
                <Line type="monotone" dataKey="Transfer" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          {/* Bar Graph */}
          <div className="bg-gray-800 text-white shadow-lg rounded-lg p-5 mb-6 w-3/6">
            <h3 className="text-lg font-medium mb-4">Monthly Sales Data</h3>
            {/* <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={barData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="#ccc" />
                <YAxis stroke="#ccc" />
                <Tooltip />
                <Legend />
                <Bar dataKey="pv" fill="#82ca9d" />
                <Bar dataKey="uv" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer> */}

            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={grpah}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="#ccc" />
                <YAxis stroke="#ccc" />
                <Tooltip />
                <Legend />
                {/* Plot bars for each transaction type */}
                <Bar dataKey="Deposit" fill="#82ca9d" />
                <Bar dataKey="Withdrawal" fill="#ff7300" />
                <Bar dataKey="Transfer" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Recent Transactions Table */}
        <div className="bg-white text-black shadow-lg rounded-lg p-5 mb-6">
          <h3 className="text-lg font-medium mb-4">Recent Transactions</h3>
          <table className="min-w-full  text-gray-600 border-collapse my-5">
            <thead>
              <tr className="text-left ">
                <th className="py-2 border-b  px-2">User Name</th>
                <th className="py-2 border-b ">Transaction ID</th>
                <th className="py-2 border-b ">Amount</th>
                <th className="py-2 border-b ">Type</th>
                <th className="py-2 border-b ">Status</th>
                <th className="py-2 border-b ">Date</th>
              </tr>
            </thead>
            <tbody className="">
              <tr className="hover:bg-gray-800 hover:text-white">
                <td className="py-2 border-b  px-2">John Doe</td>
                <td className="py-2 border-b ">TXN123456</td>
                <td className="py-2 border-b ">$500</td>
                <td className="py-2 border-b ">Deposit</td>
                <td className="py-2 border-b  text-green-500">Completed</td>
                <td className="py-2 border-b ">2024-08-17</td>
              </tr>
              <tr className="hover:bg-gray-800 hover:text-white">
                <td className="py-2 border-b   px-2">John Doe</td>
                <td className="py-2 border-b ">TXN123456</td>
                <td className="py-2 border-b ">$500</td>
                <td className="py-2 border-b ">Deposit</td>
                <td className="py-2 border-b  text-green-500">Completed</td>
                <td className="py-2 border-b ">2024-08-17</td>
              </tr>
              <tr className="hover:bg-gray-800 hover:text-white">
                <td className="py-2 border-b   px-2">John Doe</td>
                <td className="py-2 border-b ">TXN123456</td>
                <td className="py-2 border-b ">$500</td>
                <td className="py-2 border-b ">Deposit</td>
                <td className="py-2 border-b  text-green-500">Completed</td>
                <td className="py-2 border-b ">2024-08-17</td>
              </tr>
              <tr className="hover:bg-gray-800 hover:text-white">
                <td className="py-2 border-b   px-2">John Doe</td>
                <td className="py-2 border-b ">TXN123456</td>
                <td className="py-2 border-b ">$500</td>
                <td className="py-2 border-b ">Deposit</td>
                <td className="py-2 border-b  text-green-500">Completed</td>
                <td className="py-2 border-b ">2024-08-17</td>
              </tr>
              {/* Repeat rows as needed */}
            </tbody>
          </table>
        </div>

        <div className="flex gap-2">
          <div className="bg-gray-800 text-white shadow-lg rounded-lg p-5 mb-6 w-3/6 ">
            <h3 className="text-lg font-medium mb-4">Recent Users</h3>
            <table className="min-w-full  text-gray-600 border-collapse my-5">
              <thead>
                <tr className="text-left text-white">
                  <th className="py-2 border-b  px-2">User Name</th>
                  <th className="py-2 border-b ">Country</th>
                  <th className="py-2 border-b ">KYC</th>
                  <th className="py-2 border-b ">WalletBalance</th>
                  {/* <th className="py-2 border-b ">Status</th> */}
                  <th className="py-2 border-b ">Date</th>
                </tr>
              </thead>
              <tbody className="text-white">
                {users.map((item, index) => (
                  <tr className="hover:bg-white hover:text-black">
                    <td className="py-2 border-b  px-2">
                      {item.strFirstName || item.companyName}
                    </td>
                    <td className="py-2 border-b ">{item.strCountry}</td>
                    <td className="py-2 border-b ">{item.kycStatus}</td>
                    <td className="py-2 border-b ">
                      {item.currency} {item.walletBalance}
                    </td>
                    {/* <td className="py-2 border-b px-3">Deposit</td> */}
                    <td className="py-2 border-b ">
                    {new Date(item.createdAt).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}

                    </td>
                    {/* <td className="py-2 border-b  text-green-500">Completed</td> */}
                  </tr>
                ))}

                {/* <tr className="hover:bg-white hover:text-black">
                  <td className="py-2 border-b   px-2">John Doe</td>
                  <td className="py-2 border-b ">TXN123456</td>
                  <td className="py-2 border-b ">$500</td>
                  <td className="py-2 border-b px-3">Deposit</td>
                  <td className="py-2 border-b ">2024-08-17</td>
                </tr>
                <tr className="hover:bg-white hover:text-black">
                  <td className="py-2 border-b   px-2">John Doe</td>
                  <td className="py-2 border-b ">TXN123456</td>
                  <td className="py-2 border-b ">$500</td>
                  <td className="py-2 border-b  px-3 ">Deposit</td>
                  <td className="py-2 border-b ">2024-08-17</td>
                </tr>
                <tr className="hover:bg-white hover:text-black">
                  <td className="py-2 border-b   px-2">John Doe</td>
                  <td className="py-2 border-b ">TXN123456</td>
                  <td className="py-2 border-b ">$500</td>
                  <td className="py-2 border-b px-3">Deposit</td>
                  <td className="py-2 border-b ">2024-08-17</td>
                </tr> */}
                {/* Repeat rows as needed */}
              </tbody>
            </table>
          </div>
          <div className="bg-gray-800 text-white shadow-lg rounded-lg p-5 mb-6 w-3/6">
            <h3 className="text-lg font-medium mb-4">Recent Withdrawals</h3>
            <table className="min-w-full  text-white border-collapse my-5">
              <thead>
                <tr className="text-left">
                  {/* <th className="py-2 border-b  px-2 max-w-[100px] truncate">WithdrawalsID</th> */}
                  {/* <th className="py-2 border-b  px-2  max-w-[92px] break-words ">WithdrawalsID</th> */}
                  <th className="py-2 border-b  px-2">ID</th>
                  <th className="py-2 border-b ">UserName</th>
                  <th className="py-2 border-b ">Amount</th>
                  <th className="py-2 border-b ">Currency</th>
                  <th className="py-2 border-b px-6 ">Date</th>
                  <th className="py-2 border-b ">Status</th>
                  <th className="py-2 border-b ">Paymet Methode</th>
                </tr>
              </thead>
              <tbody className="">
                {withdetails.map((item, index) => (
                  <tr className="hover:bg-white hover:text-black">
                    <td className="py-2 border-b  px-2">
                      {truncateObjectId(item.payment_Id)}
                    </td>
                    <td className="py-2 border-b ">{item.UserName}</td>
                    <td className="py-2 border-b ">{item.amount}</td>
                    <td className="py-2 border-b ">{item.currency}</td>
                    <td className="py-2 border-b ">
                      {new Date(item.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </td>
                    <td className="py-2 border-b  text-green-500">
                      {item.payment_Status}
                    </td>
                    <td className="py-2 border-b px-10 ">
                      {item.paymentSource}
                    </td>
                  </tr>
                ))}
                {/* <tr className="hover:bg-white hover:text-black">
                  <td className="py-2 border-b  px-2">001</td>
                  <td className="py-2 border-b ">John</td>
                  <td className="py-2 border-b ">500</td>
                  <td className="py-2 border-b ">Aed</td>
                  <td className="py-2 border-b ">2024-08-17</td>
                  <td className="py-2 border-b  text-green-500">Completed</td>
                  <td className="py-2 border-b  ">Bank Trasfer</td>
                </tr>
                <tr className="hover:bg-white hover:text-black">
                  <td className="py-2 border-b  px-2">001</td>
                  <td className="py-2 border-b ">John</td>
                  <td className="py-2 border-b ">500</td>
                  <td className="py-2 border-b ">Aed</td>
                  <td className="py-2 border-b ">2024-08-17</td>
                  <td className="py-2 border-b  text-green-500">Completed</td>
                  <td className="py-2 border-b  ">Bank Trasfer</td>
                </tr>
                <tr className="hover:bg-white hover:text-black">
                  <td className="py-2 border-b  px-2">001</td>
                  <td className="py-2 border-b ">John</td>
                  <td className="py-2 border-b ">500</td>
                  <td className="py-2 border-b ">Aed</td>
                  <td className="py-2 border-b ">2024-08-17</td>
                  <td className="py-2 border-b  text-green-500">Completed</td>
                  <td className="py-2 border-b  ">Bank Trasfer</td>
                </tr>
                <tr className="hover:bg-white hover:text-black">
                  <td className="py-2 border-b  px-2">001</td>
                  <td className="py-2 border-b ">John</td>
                  <td className="py-2 border-b ">500</td>
                  <td className="py-2 border-b ">Aed</td>
                  <td className="py-2 border-b ">2024-08-17</td>
                  <td className="py-2 border-b  text-green-500">Completed</td>
                  <td className="py-2 border-b  ">Bank Trasfer</td>
                </tr> */}

                {/* Repeat rows as needed */}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pending KYC Verifications */}
        {/* <div className="bg-gray-800 text-white shadow-lg rounded-lg p-5">
          <h3 className="text-lg font-medium mb-4">Pending KYC Verifications</h3>
          <ul>
            <li className="flex justify-between items-center py-2 border-b border-white">
              <span>Jane Smith</span>
              <span className="text-gray-400 hover:underline cursor-pointer">View More</span>
            </li>
          </ul>
        </div> */}

        {/* Quick Actions */}
        {/* <div className="flex justify-end mt-6">
          <button className="bg-white text-black p-3 rounded-lg shadow-lg hover:scale-105 transform transition-transform">
            Approve All Pending KYCs
          </button>
        </div> */}
        {/* Recent Users */}
        {/* <div className="bg-gray-800 text-white shadow-lg rounded-lg p-5 mb-6">
          <h3 className="text-lg font-medium mb-4">Recent Users</h3>
          <ul>
            <li className="flex justify-between items-center py-2 border-b">
              <span>John Doe</span>
              <span className="text-gray-500">2024-08-17</span>
            </li>
            <li className="flex justify-between items-center py-2 border-b">
              <span>Jane Smith</span>
              <span className="text-gray-500">2024-08-16</span>
            </li>
          </ul>
        </div> */}
        {/* <div className="dashboard-latest-section grid grid-cols-2 gap-4">
    <div className="latest-users">
      <h3 className="text-lg font-bold  text-black mb-4">Latest 10 Users</h3>
      <table className="min-w-full bg-gray-700">
        <thead>
          <tr>
            <th className="px-4 py-2">User ID</th>
            <th className="px-4 py-2">Username</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Signup Date</th>
            <th className="px-4 py-2">Country</th>
            <th className="px-4 py-2">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">001</td>
            <td className="border px-4 py-2">JohnDoe</td>
            <td className="border px-4 py-2">john@example.com</td>
            <td className="border px-4 py-2">2024-08-17</td>
            <td className="border px-4 py-2">USA</td>
            <td className="border px-4 py-2">Active</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="latest-withdrawals">
      <h3 className="text-lg font-bold text-black mb-4">Latest 10 Withdrawals</h3>
      <table className="min-w-full  bg-gray-700">
        <thead>
          <tr>
            <th className="px-4 py-2">Withdrawal ID</th>
            <th className="px-4 py-2">User ID</th>
            <th className="px-4 py-2">Amount</th>
            <th className="px-4 py-2">Currency</th>
            <th className="px-4 py-2">Date</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Payment Method</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">W001</td>
            <td className="border px-4 py-2">001</td>
            <td className="border px-4 py-2">$500</td>
            <td className="border px-4 py-2">USD</td>
            <td className="border px-4 py-2">2024-08-17</td>
            <td className="border px-4 py-2">Completed</td>
            <td className="border px-4 py-2">Bank Transfer</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> */}
      </div>
    </div>
  );
};

export default AdminDashboard;
